.project-container {
    display: flex;
    margin-top: 5em;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 2vw;
}

.btns-container{
    display: flex;
}

.project-btn {
    padding: 5px;
    margin-right: 1em;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: background-color 0.20s ease-in-out;
    border-radius: 5px;
}

.project-btn:hover {
    background-color: var(--secondary-color);
}

.project-img {
    max-width: 30vw;
    max-height: 53vh;
    aspect-ratio: 16/9;
    size: cover;
    margin-left: 2vw;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, .2);
}

@media(max-width: 768px) {
    .project-img {
        display: none;
    }
    .project-btn {
        margin-top: 1em;
    }
    .text-container h3{
        margin-bottom: .25em;
    }
}