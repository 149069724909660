* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
}

:root {
    --primary-color: #26408B;
    --secondary-color: #A6CFD5;
    --tertiary-color: #C2E7D9;
    --quatenary-color: #0d0221;
}

body {
    background-color: #fff;
}

a {
    text-decoration: none;
    color: var(--quatenary-color);
}

a.btn {
    width: 7em;
    height: 3.5em;
    background-color: var(--primary-color);
    border: none;
    text-align: center;
}

h1 {
    font-size: 6rem;
}

h2  {
    font-size: 4rem;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.5rem;
}

.container {
    margin: 3em;
}

.photo-container {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    top: 0;
    z-index: -1;
    position: absolute;
}

.photo {
    position: relative;
    background-image: url("../assets/tyler.jpg");
    height: 100%;
    max-height: inherit;
    widht: 100%;
    max-width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    background-position: 50%;
}

@media screen and (max-width: 1000px) {
    .container {
        margin: 1em;
    }
    .photo {
        background-position: 75%;
    }
}