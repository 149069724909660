.home {
    position: relative;
    display: flex;
    height: 95vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.name {
    color: white;
    height: 100%;
}

.name h3 {
    margin-left: 0.5em;
}

.button-container {
    display: flex;
    margin-left: 15px;
}

.button {
    display: block;
    height: 3em;
    width: 8em;
    overflow: visible;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--secondary-color);
    border-radius: 5px;
    margin-top: 10px;
}


.button:hover {
    transform: rotate(1deg) scale(1.05, 1.05);
    transition: transform .25s;
}

@media screen and (max-width: 1000px) {
    .name-container {
        position: absolute;
        bottom: 0;
    }
}

