.card {
    width: 24vw;
    height: 30vh;
    margin: 2.2em 1.5em 2.2em 1.5em;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.5);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: auto;
    background-color: var(--quatenary-color);
    transition: transform .15s ease-in-out, background-color .15s ease-in-out;
    z-index: 0;
    cursor: pointer;
}

.card:hover {
    transform: rotate(2.5deg) scale(1.1, 1.1);
    background-color: var(--primary-color);
}


.img {
    height: 50%;
    margin: 2vh 0 3vh 0;
    align-self: center;
}


@media(max-width: 1350px) {
    .card {
        height: 18rem;
        width: 30%;
        margin: 1.1em .75em 1.5em .75em;
    }
    .text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 900px) {
    .card {
        width: 12rem;
        height: 12rem;
    }
    .text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 550px) {
    .card {
        width: 30%;
        height: 5rem;
        margin: .75em .50em .75em .50em;
    }
    .img {
        height: 50%;
        margin: 1vh 0 1vh 0;
        align-self: center;
    }
}
