.contact {
    background-color: var(--secondary-color);
    color: white;
    height: 90vh;
    padding-top: 1em;
    margin-top: 5em;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 65vh;
}

.form-container h3 {
    margin-bottom: 1em;
    margin-top: 1em;
}

.contact form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
}

.contact-btn {
    height: 2.5em;
    width: 5em;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: var(--primary-color);
    text-align: center;
    cursor: pointer;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

input, textarea {
    height: 2em;
    padding-left: 5px;
    background-color: #fff;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 1em;
}

textarea {
    min-height: 65px;
    padding-top: 5px;
    padding-bottom: 5px; 
}

label {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600; 
}

@media screen and (max-width: 1000px) {
    .contact-form {
        width: 80%;
    }
}