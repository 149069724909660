.about {
    height: auto;
    background-color: var(--secondary-color);
    color: white;
    z-index: -1;
    padding-top: 1em;
    max-width: 100vw;
}

.flex-container {
    width: 100%;
    padding-bottom: .5em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.club {
    display: flex;
    width: 80 em;
    margin-bottom: 1em;
}

.experience-card {
    display: flex;
    flex-direction: column;
}

.experience-card div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
}


.experience-card a {
    color: default;
    width: 100px;
}

.zivaro {
    width: 5em;
    background-image: url("../assets/Zivaro-client-logo-01.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1em;
}

.ra {
    width: 5em;
    background-image: url("../assets/boulder.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1em;
}

.robotics {
    width: 5em;
    background-image: url("../assets/awesome-logo.webp");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1em;
}

.blueprint {
    width: 5em;
    background-image: url("../assets/blueprint.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1em;
}

.experience-txt {
    width: 65vw;
    margin-bottom: 0.5em;
}

.about-btn {
    overflow: visible;
    width: 10px;
    height: 2.5em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: var(--quatenary-color);
}

.about-btn:hover {
    background-color: rgb(36, 36, 36);
}

@media screen and (max-width: 768px) {
    .experience-card div {
        display: flex;
        flex-direction: column;
    }
}