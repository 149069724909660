.drop-btn {
    position: fixed;
    right: 10px;
    top: 5px;
}

.drop-hamburger {
    height: 1.75em;
    width: 1.75em;
}

.drop-hamburger:hover {
    color: var(--secondary-color);
}

.dropdown-menu {
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 20%;
    top: 0;
    background-color: var(--quatenary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    cursor: default;
}


.dropdown-menu.active {
    right: -20vw;
    transform: translateX(-20vw);
    transition: transform 500ms ease-in-out;
    pointer-events: auto;
}

.dropdown-menu.hidden {
    right: -20vw;
    transform: translateX(-0vw);
    transition: transform 500ms ease-in-out;
}

.dropdown-menu a {
    margin: 5px;
    color: white;
}

.dropdown-menu a:hover {
    background-color: var(--primary-color);
}

.drop-exit {
    margin-top: 150px !important;
}

@media screen and (max-width: 1000px) {
    .dropdown-menu {
        width: 80%;
    }
    .dropdown-menu.active {
    right: -80vw;
    transform: translateX(-80vw);
    transition: transform 500ms ease-in-out;
    pointer-events: auto;
    }

    .dropdown-menu.hidden {
        right: -80vw;
        transform: translateX(-0vw);
        transition: transform 500ms ease-in-out;
    }
}
